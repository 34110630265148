<template>
    <div class="cp-box">
        <el-row v-has-permi="['batch:execLog:query']" class="sub-search">
            <el-form ref="form" :model="form" :inline="true" style="text-align: left;">
                <el-form-item>
                    <el-input v-model="form.searchValue" style="width: 255px" placeholder="サプライヤー名を検索する"
                              suffix-icon="el-icon-search"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="form.functionId" placeholder="全ての機能" style="width: 140px">
                        <el-option v-for="(item,index) in functions" :key="'s_'+index"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="form.status" placeholder="全ての結果" style="width: 140px">
                        <el-option v-for="(item,index) in options" :key="'n_'+index"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="searchBatch">検索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-refresh-right" @click="reForm">クリア</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row class="content1">
            <el-row style="text-align: left">
                <el-button type="text" class="btn-width-sm" icon="el-icon-back" style="color: gray" @click="backList">戻る
                </el-button>
            </el-row>
            <el-table :data="tableData" style="width: 100%;" :max-height="tableHeight">
                <el-table-column align="center" prop="supplierName" label="サプライヤー名称" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="functionName" label="機能名" width="130" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="syncData" label="同期データ" width="230">
                    <template slot-scope="scope">
                        <cus-tooltip :val="scope.row.syncData"></cus-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="failureCount" label="失敗の回数" width="100">
                </el-table-column>
                <el-table-column align="center" prop="status" label="同期結果" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == '0'"><i class="el-icon-success" style="color: #66be96;"></i> 成功</span>
                        <span v-else><i class="el-icon-warning" style="color: #d9011d;"></i> 失敗</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.status == '1'" class="small-btn" type="primary" size="small"
                                   @click="errorDetail(scope.row)">失敗明細
                        </el-button>
                        <el-button v-has-permi="['batch:execLog:execDataSync']" v-if="scope.row.status == '1'"
                                   class="small-btn" type="primary" size="small" @click="execDataSync(scope.row)">
                            再同期データ
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="pagina-box" background layout="prev, pager, next"
                           :total="total" :current-page="pageNo"
                           :page-size="pageSize"
                           @current-change="onPage"
                           @size-change="onSizeChange">
            </el-pagination>
        </el-row>
        <el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" width="700"
                   :close-on-click-modal="false" :show-close="false">
            <div slot="title" class="dialog-title-box">
                <span>{{ title }}</span>
            </div>
            <batch-record-error-detail style="height: 500px;"  :betch="betch"></batch-record-error-detail>
            <span slot="footer" class="dialog-footer" style="margin-top: 10px;">
                <el-button class="btn-width-sm" @click="dialogVisible = false" icon="el-icon-back">戻る</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import batchRecordErrorDetail from "./batch-record-error-detail"
    import CusTooltip from "../../components/CusTooltip";
    export default {
        name: "exec-log-detail2",
        props: ['exInfo'],
        components: {batchRecordErrorDetail, CusTooltip},
        data() {
            return {
                title: '',
                betch: {},
                editRow: '',
                tableHeight: 0,
                form: {
                    searchValue: '',
                    jobLogId: '',
                    functionId:'',
                    status: '',
                },
                dialogVisible: false,
                functions: [],
                options: [
                    {
                        label: '全ての結果',
                        value: ''
                    }, {
                        label: '成功',
                        value: '0'
                    }, {
                        label: '失敗',
                        value: '1'
                    },
                ],
                tableData: [],
                total: 0,
                pageNo: 1,
                pageSize: 10,
            }
        },
        mounted() {
            this.getFunctionList();
            this.editRow = this.exInfo;
            this.getTableMaxHeight();
            let _this = this;
            window.onresize = function () {//テーブルを高度に適応させるための方法
                _this.getTableMaxHeight();//容器の現在の高さを取得し、テーブルの最大高さをリセットする
            }
            this.searchBatch();
        },
        methods: {
            async getFunctionList(){
                this.functions = [
                    { label: '全ての機能', value: ''}
                ]
                let res = await this.$http.batchRecord.functionList();
                if (res.code === 200) {
                    res.data.forEach(item => {
                        this.functions.push({
                            label: item.name,
                            value: item.functionId
                        })
                    })
                }
            },
            onPage(num) {
                this.pageNo = num;
                this.searchBatch();
            },
            onSizeChange(size) {
                this.pageNo = 1;
                this.pageSize = size
                this.searchBatch();
            },
           errorDetail(row) {
                this.title = row.supplierName + ' | ' +row.functionName;
                this.betch = row;
                this.dialogVisible = true;
            },
            /**
             * バッチデータ再同期
             */
            async execDataSync(row) {
                let res = await this.$http.batchRecord.execDataSync({jobSyncDataLogId: row.jobSyncDataLogId});
                if (res.code === 200) {
                    this.$message.success(res.msg)
                    this.searchBatch()
                }
            },
            backList(){
                this.$emit('backList')
            },
            /**
             *クエリ条件をリセットする
             */
            reForm() {
                this.form = {
                    searchValue: '',
                    jobLogId: '',
                    functionId: '',
                    status: ''
                }
            },
            async searchBatch() {
                this.form.jobLogId = this.editRow.jobLogId;
                this.form.pageNum = this.pageNo;
                this.form.pageSize = this.pageSize;
                let res = await this.$http.batchRecord.detailList(this.form);
                if (res.code === 200) {
                    this.total = res.total;
                    this.tableData = res.rows;
                }
            },
            //容器の現在の高さを取得し、テーブルの最大高さをリセットする
            getTableMaxHeight() {
                this.$nextTick(function () {

                    if (!document.querySelector(".content1")) {
                        return;
                    }
                    let box = document.querySelector(".content1").attributes
                    let box_clientHeight = box[0].ownerElement.clientHeight;
                    this.tableHeight = box_clientHeight - 130;
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
  .sub-search {
    height: 75px;
    background: white;
    box-shadow: 0px 0px 15px rgba(170, 170, 170, 35%);
    padding: 18px 30px 0 30px;
    > > .el-input__prefix {
      color: #AAAAAA;;
    }

    /deep/ .el-form-item__content {
      margin-left: 0px !important;
    }

    .input-item {
      /deep/ .el-input__suffix {
        left: 5px;
        right: auto;
      }

      /deep/ .el-input--suffix .el-input__inner {
        padding-right: 10px;
        padding-left: 30px;
      }
    }

    .date-item {
      /deep/ .el-input__prefix {
        left: 0px;
        -webkit-transition: all .3s;
        transition: all .3s;
        width: 40px;
        background: rgba(242, 242, 242, 1);
      }

      /deep/ .el-input__inner {
        padding-left: 45px;
      }
    }
  }
  .content1 {
    box-shadow: 0px 0px 15px rgba(170, 170, 170, 35%);
    padding: 30px 30px;
    height: calc(100vh - 320px);
    background: rgba(255, 255, 255, 1);
    margin-top: 20px;
    .pagina-box {
      margin-top: 20px;
      text-align: right;
    }
  }
  .small-btn {
    color: white;
    height: 24px;
    line-height: 1px;
  }
  .content {
    max-width: 320px;
  }

</style>
