<template>
    <div>
        <el-table
                :data="tableData"
                style="width: 100%" max-height="500">
            <el-table-column prop="createTime" label="実行時間" width="180">
            </el-table-column>
            <el-table-column prop="failureInfo" label="誤り情報">
                <template slot-scope="scope">
                    <cus-tooltip :val="scope.row.failureInfo"></cus-tooltip>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import CusTooltip from "../../components/CusTooltip";
export default {
  name: "batchRecordErrorDetail",
  props: ['betch'],
  components: {CusTooltip},
  data() {
    return {
      tableData: [],
      isShowTooltip: false
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList(){
      let res = await this.$http.batchRecord.failureLogList({jobSyncDataLogId: this.betch.jobSyncDataLogId});
      if (res.code === 200) {
        this.tableData = res.rows;
      }
    }

        },
    }
</script>

<style lang="scss" scoped>
</style>
